.Header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;

    background: url("La-Palma---1-compressor.jpeg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    text-align: center;
}

.Header-Face {
    background-image: url("Image---Matthias-Gartemann---1-compressor.jpeg");
    /*background-size: contain;*/
    background-size: cover;
    height: 10em;
    width: 10em;
    border-radius: 100%;
    border: 6px solid #228461;
}

.Header-Description {
    margin-bottom: -60px;
    animation-duration: 1s;
    animation-delay: 300ms ;
    animation-name: slidetop;
    animation-fill-mode: forwards;
}

@keyframes slidetop {
    from {
        margin-bottom: -60px;
        width: 100%;
        opacity: 0%;
    }

    to {
        margin-bottom: 0px;
        width: 100%;
        opacity:1;
    }
}