.Main-Box {
    background-color: white;
}

.Main-Container {
    max-width: 920px;
    margin: 0 auto;
    padding: 25px 10px;
}

@media (min-width: 540px) {
    .Main-Container {
        padding: 50px 5em;
    }
}

.Main-Article-Step {
    border-left: 6px solid white;
    padding-left: 6px;
    margin-left: -12px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.Main-Article-Step:hover {
    border-left: 6px solid #228461;
    background-color: #d2e6df;
}

.ExternLinkIcons {
    text-align: center;
    padding-top: 20px;
}

.ExternLinkIcon {
    width: 35px;
    height: 35px;
    padding: 0 20px;
}

.Linkedin {
    background-color: white;
}

h2 {
    padding-top: 40px;
    padding-bottom: 5px;
}

h3 {
    font-size: 22px;
    height: 22px;
}

h4 {
    padding-bottom: 14px;
}

h5, h4 {
    font-size: 13px;
    height: 13px;
}

.JobDescription {
    padding-bottom: 7px;
}

.JustifyItem {
    margin: 5px 0px;
}

hr::before {
    line-height: 1.4;
    font-style: italic;
    text-indent: 0.6em;
    letter-spacing: 0.6em;
    content: "...";
    box-sizing: inherit;
}
